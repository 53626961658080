<template>
	<v-container v-if="$can('view', 'Faq')" fluid>
		<div v-if="$can('create', 'Faq')" class="text-right">
			<v-btn rounded color="primary" dark @click="createFaq()"
				>+ Create Faq</v-btn
			>
		</div>

		<div v-if="faqs.length > 0" class="mt-4">
			<v-row justify="center">
				<v-expansion-panels v-model="openedPanel" accordion>
					<v-expansion-panel
						v-for="(faq, i) in faqs"
						:key="faq.id"
						class="pa-0"
					>
						<v-expansion-panel-header
							>{{ faq.question
							}}<template v-slot:actions>
								<v-icon color="var(--blue)">$expand</v-icon>
							</template></v-expansion-panel-header
						>
						<v-expansion-panel-content class="pt-4">
							<div v-html="faq.answer"></div>
						</v-expansion-panel-content>
						<v-divider></v-divider>
						<div
							class="px-6 py-4 card-actions d-flex"
							v-if="openedPanel === i"
						>
							<UnoEditButton
								v-if="$can('update', 'Faq')"
								:to="{
									name: 'faq.edit',
									params: { id: faq.id },
								}"
							></UnoEditButton>

							<UnoPublishButton
								v-if="$can('publish', 'Faq')"
								:isPublished="faq.isPublished"
								@click.native="toggleStatus(faq)"
							></UnoPublishButton>

							<v-spacer></v-spacer>

							<UnoDeleteButton
								v-if="$can('delete', 'Faq')"
								@confirm="deleteFaq(faq.id)"
							></UnoDeleteButton>
						</div>
					</v-expansion-panel>
				</v-expansion-panels>
			</v-row>
		</div>

		<NoData v-else></NoData>

		<Paginator
			:paginator="paginator"
			:url="getUrl()"
			@update="changePage($event)"
		></Paginator>
	</v-container>
</template>

<script>
// ACTION BUTTONS
import UnoPublishButton from '@/components/Buttons/UnoPublishButton'
import UnoEditButton from '@/components/Buttons/UnoEditButton'
import UnoDeleteButton from '@/components/Buttons/UnoDeleteButton'
// Alerts
// import DeleteAlert from '@/components/Alerts/DeleteAlert'

import Paginator from '../../components/Paginator'
import NoData from '../../components/NoData'
export default {
	components: {
		UnoEditButton,
		UnoPublishButton,
		UnoDeleteButton,
		// DeleteAlert,
		Paginator,
		NoData,
	},
	data() {
		return {
			openedPanel: null,
			// deleteConfirm: false,
			// showDelete: false,
			// id: null,
		}
	},
	created() {
		this.$emit('filter')
		this.$store.dispatch('faq/getListingData', { root: true })
	},
	// destroyed() {
	// 	this.$store.commit('faq/clearState')
	// },
	computed: {
		faqs: {
			get() {
				return this.$store.state.faq.faqs
			},
			set(value) {
				this.updateFaqs(value)
			},
		},
		paginator: {
			get() {
				return this.$store.state.faq.paginator
			},
			set(value) {
				this.updatePaginator(value)
			},
		},
	},
	methods: {
		updatePaginator(value) {
			this.$store.commit('faq/updatePaginator', value)
		},
		updateFaqs(value) {
			this.$store.commit('faq/updateFaqs', value)
		},
		getUrl() {
			const projectId = this.$store.state.app.projectId
			let url = `/${projectId}/faqs`
			return url
		},
		changePage(data) {
			this.faqs = data.data
			this.paginator = data.paginator
		},
		openPanel(index) {
			this.openedPanel = index
		},
		deleteFaq(id) {
			this.$store.dispatch('faq/deleteFaq', {
				faqId: id,
			})
		},
		toggleStatus(faq) {
			const projectId = this.$store.state.app.projectId

			this.axios({
				method: 'post',
				url: `/${projectId}/faqs/toggle-status/${faq.id}`,
			}).then((res) => {
				faq.isPublished = res.data.data.isPublished
			})
		},
		createFaq() {
			this.$router.push({
				name: 'faq.create',
			})
		},
	},
}
</script>

<style scoped>
.v-expansion-panel {
	border-radius: 4px;
}
.v-expansion-panel-header--active,
.v-expansion-panel-header--active i {
	background-color: #fff !important;
	color: #303f9f !important;
	border-bottom: none !important;
}
</style>
